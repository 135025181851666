// components/BlogDetail.js
import React, { useEffect } from "react";
import BloggerProfile from "../bloggerProfile/bloggerProfile";
import Typography from "@mui/material/Typography";
import { Stack, useMediaQuery } from "@mui/material";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import HelmetComponet from "../helmet/helmet";
import { PageCount } from "../../hooks/pageCount";

const BlogDetail = ({ blog }) => {
  const {
    post_id,
    keywords,
    content,
    title,
    subtitle,
    created_at,
    username,
    path,
    image,
  } = blog;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const matches = useMediaQuery("(min-width:768px)");
  const keyData = keywords || "[]";
  const parsedKeywords = JSON.parse(keyData);
  if (!blog) {
    return <div>Blog not found</div>;
  }
  const sanitizedContent = DOMPurify.sanitize(content);
  const renderContent = (html) => {
    return parse(html, {
      replace: (node) => {
        if (node.name === "code" && node.attribs && node.attribs.class) {
          const language = node.attribs.class.split("-")[1]; // e.g., 'language-javascript'
          return (
            <SyntaxHighlighter style={dark} language={language}>
              {node.children[0]?.data.trim()}
            </SyntaxHighlighter>
          );
        }
      },
    });
  };
  useEffect(() => {
    if (post_id) {
      const postID = parseInt(post_id);
      PageCount(postID);
    }
  }, [post_id]);
  return (
    <Stack my={3}>
      <HelmetComponet
        title={title}
        description={subtitle}
        path={`${baseUrl}/blog/${path}`}
        keywords={parsedKeywords}
        image={`${baseUrl}/clevermedium_backend/${image}`}
      />
      <Typography
        variant="h1"
        component="h1"
        sx={{ fontWeight: "bold" }}
        maxWidth={800}
        mb={2}
        fontSize={matches ? "2em" : "1.5em"}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="h2"
          component="h4"
          maxWidth={800}
          fontSize={matches ? "1.2em" : "1em"}
          mb={3}
        >
          {subtitle}
        </Typography>
      )}
      <BloggerProfile name={username} date={created_at} blog={blog} />
      {image && (
        <img src={`${baseUrl}/clevermedium_backend/${image}`} alt={title} />
      )}
      <Stack className="cm-main--blog">{renderContent(sanitizedContent)}</Stack>
    </Stack>
  );
};

export default BlogDetail;
