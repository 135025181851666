import AppRouter from "./routers/routers";
import { AppContext } from "./context/AppContext";
import Loader from "./components/loader/loader";
import { useContext, useMemo, useState } from "react";
import ToggleButton from "./components/toggleButton/toggleButton";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const App = () => {
  const { state } = useContext(AppContext);
  const [darkMode, setDarkMode] = useState(false);
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
        },
      }),
    [darkMode]
  );
  const toggleThemeOnchange = () => {
    setDarkMode((prevMode) => !prevMode);
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRouter />
      {state.loader && <Loader show={state.loader} />}
      <ToggleButton toggleThemeOnchange={toggleThemeOnchange} />
    </ThemeProvider>
  );
};

export default App;
