// pages/AdminSignIn.js
import React, { useContext } from "react";
import axios from "axios";
import { login } from "../../routers/auth";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import CleverLogo from "../../components/logo/logo";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";

const AdminSignIn = () => {
  const theme = useTheme();
  const { updateLoader } = useContext(AppContext);
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  // const handleSignIn = async () => {
  //   // Perform validation if needed
  //   try {
  //     updateLoader(true);
  //     const response = await axios.post(
  //       `${apiUrl}/clevermedium_backend/login.php`,
  //       {
  //         email: email,
  //         password: password,
  //       }
  //     );
  //     const token = response.data.jwt;
  //     if (token) {
  //       login(token);
  //       navigate("/admin/bloglist");
  //     }
  //     updateLoader(false);
  //   } catch (error) {
  //     console.error("Error during authentication:", error.message);
  //     updateLoader(false);
  //   }
  // };

  const handleSuccess = async (credentialResponse) => {
    const jwtToken = credentialResponse.credential;
    const user = jwtDecode(jwtToken);
    // console.log("JWT user:", user);
    if (user) {
      try {
        updateLoader(true);
        const response = await axios.post(
          `${apiUrl}/clevermedium_backend/googleuser.php`,
          {
            sub: user.sub,
            name: user.name,
            email: user.email,
            profilepicture: user.picture,
          }
        );
        localStorage.setItem("user", JSON.stringify({
          name: response.data.data.name,
          email: response.data.data.email,
          profilepicture: response.data.data.profilepicture,
          role: response.data.data.role,
          userid: response.data.data.userid,
        }));
        login(jwtToken);
        // navigate("/admin/bloglist");
        navigate(-1);
        updateLoader(false);
      } catch (error) {
        console.error("Error during authentication:", error.message);
        updateLoader(false);
      }
    }
    // Send this token to your backend for further validation
  };

  const handleError = () => {
    console.error("Login Failed");
  };

  return (
    <Box display={"flex"} height={"100%"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        maxWidth={400}
        width={"100%"}
        alignItems={"center"}
        margin={"auto"}
        justifyContent={"center"}
      >
        <Link to="/">
          <CleverLogo mode={theme.palette.mode} />
        </Link>
        <Typography my={4} variant="body">
          Sign In with Google
        </Typography>
        {/* <TextField
          type="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginBottom: 3, width: "100%" }}
        />

        <TextField
          type="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginBottom: 3, width: "100%" }}
        />

        <Button
          sx={{
            marginBottom: 3,
            height: 56,
            width: "100%",
            bgcolor: theme.palette.text.primary,
            ":hover": {
              bgcolor: theme.palette.grey[800],
            },
          }}
          variant="contained"
          onClick={handleSignIn}
        >
          Sign In
        </Button> */}
        <Box width={"100%"}>
          {/* <Divider>OR</Divider> */}
          <Box width={"100%"} display={"flex"}>
            <GoogleLogin
              width={400}
              style={{ width: "100%" }}
              onSuccess={handleSuccess}
              onError={handleError}
              size="large"
              logo_alignment="center"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminSignIn;
