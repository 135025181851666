import React from "react";
import Box from "@mui/material/Box";
import BlogCard from "../blogCard/blogCard";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import HelmetComponet from "../helmet/helmet";
import image from "../../assets/images/clevermedium-logo.png";

const BlogList = ({ blogs }) => {
  const matches = useMediaQuery("(min-width:768px)");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  return (
    <Box sx={{ flexGrow: 1 }} mt={2}>
      <HelmetComponet
        title="Learn Coding and Technology | Inspiring Stories & In-Depth Guides"
        description="Explore an inspiring collection of coding tutorials, technology insights, and real-life programming stories. Empowering software engineers, professionals, and students globally."
        path={baseUrl}
        keywords="javascript, reactjs, Learn coding, Technology insights, Programming guides, Real-life coding stories, Software engineering tips"
        image={image}
      />
      <Typography
        textAlign={"center"}
        fontWeight={700}
        fontSize={matches ? "2em" : "1.5em"}
        variant="h1"
        mb={3}
      >
        Clevermedium Blog
      </Typography>
      <Typography textAlign={"center"} variant="h6">
        Hi, I&apos;m{" "}
        <Typography variant="h6" component={"span"} color="#ff0081">
          Kesavamoorthi
        </Typography>{" "}
        👋
      </Typography>
      <Typography mb={3} textAlign={"center"} display="block" gutterBottom>
        I enjoy crafting web experiences, especially on the front-end. Welcome
        to my site, clevermedium, where I blog, share insights, and post
        tutorials!
      </Typography>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        {blogs.map((blog) => (
          <Grid item xs={12} key={blog.post_id}>
            <BlogCard blog={blog} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BlogList;
