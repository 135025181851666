import Header from "../../components/header/header";
import { Container, MainContainer } from "./mainStyle";
import Footer from "../../components/footer/footer";
import { useTheme } from "@emotion/react";

export const MainLayout = ({ children }) => {
  const theme = useTheme();
  return (
    <Container
      className={theme.palette.mode === "dark" ? "dark-theme" : "light-theme"}
    >
      <Header />
      <MainContainer>{children}</MainContainer>
      <Footer />
    </Container>
  );
};
