import React from "react";
import { MainLayout } from "../../layouts/main/main";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import HelmetComponet from "../../components/helmet/helmet";

const AboutUs = () => {
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <MainLayout>
      <HelmetComponet
        title="About Us"
        description="Welcome to Clevermedim, your go-to destination for insightful programming stories, technical knowledge, and a deep dive into the world of coding and technology."
        path="https://clevermedium.com/about-us"
        keywords="About Us, clevermedium, programming stories, technical knowledge, coding and technology"
        image=""
      />
      <Stack my={3}>
        <Typography
          variant="h1"
          component="h1"
          sx={{ fontWeight: "bold" }}
          fontSize={matches ? "2em" : "1.5em"}
          mb={1}
        >
          About Us
        </Typography>
        <p>
          Welcome to Clevermedim, your go-to destination for insightful
          programming stories, technical knowledge, and a deep dive into the
          world of coding and technology. We are passionate about sharing our
          love for programming languages, exploring the latest tech trends, and
          weaving together the vibrant tapestry of the digital realm.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          {" "}
          Our Mission
        </Typography>
        <p>
          At Clevermedium, our mission is clear: to make technology accessible
          and enjoyable for everyone. We believe that the world of programming
          is not just a set of complex codes and algorithms but a fascinating
          journey filled with stories, challenges, and endless possibilities.
          Through our blog, we aim to demystify the intricacies of programming
          languages and provide a platform where tech enthusiasts, beginners,
          and experts alike can come together to learn, share, and grow.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          {" "}
          What Sets Us Apart
        </Typography>
        <p>
          {" "}
          <b>Authentic Stories: </b>We don&apos;t just talk about code; we
          tell stories. Our articles are crafted to engage and inspire, giving
          you a unique perspective on the people, projects, and innovations
          shaping the tech landscape.
        </p>
        <p>
          {" "}
          <b>Diverse Perspectives: </b>We celebrate diversity in technology. Our
          content reflects a wide range of voices and experiences, ensuring that
          everyone feels represented and empowered in the ever-evolving tech
          world.
        </p>
        <p>
          {" "}
          <b>Practical Knowledge:</b> Whether you&apos;re a seasoned developer
          or just starting your coding journey, you&apos;ll find practical
          insights, tips, and tutorials that you can apply in real-world
          scenarios. We believe in learning by doing.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          {" "}
          Connect With Us
        </Typography>
        <p>
          Join our community and be a part of the conversation. Follow us on
          <a href="mailto:contact@clevermedium.com">contact@clevermedium.com</a> for the latest updates, discussions, and
          behind-the-scenes looks at the world of Clevermedium.
        </p>
        <p>
          Thank you for being a part of our journey. Together, let&apos;s explore the
          endless possibilities that code and technology have to offer.
        </p>
        <div>Happy coding! </div>
        Clevermedium Team
      </Stack>
    </MainLayout>
  );
};

export default AboutUs;
