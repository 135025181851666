import axios from "axios";

export const PageCount = (post_id) => {
  if (!post_id) {
    return;
  }
  const visitedPages = JSON.parse(sessionStorage.getItem("visitedPages")) || {};
  const apiUrl = process.env.REACT_APP_BASE_URL;

  // Check if this page has been visited
  if (!visitedPages[post_id]) {
    // Mark the page as visited
    visitedPages[post_id] = true;
    sessionStorage.setItem("visitedPages", JSON.stringify(visitedPages));
    axios.post(`${apiUrl}/clevermedium_backend/page_count.php`, { post_id })
      .then((response) => {
        console.log("Page view incremented:", response.data);
      })
      .catch((error) => {
        console.error("Error incrementing page view:", error);
      });
  }
};
