import React from "react";
import { Toggle } from "./toggleButtonStyles";
import { useTheme } from "@emotion/react";

const ToggleButton = ({ toggleThemeOnchange }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  // Toggle function to switch themes
  const toggleTheme = () => {
    toggleThemeOnchange();
  };

  return (
    <Toggle onClick={toggleTheme}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="70"
        viewBox="0 0 50 119"
      >
        <g transform="translate(955 -1701) rotate(90)">
          <path
            stroke="#818181"
            fill={mode === "dark" ? "#3e3e3e" : "#ffffff"}
            d="M24,0H60a0,0,0,0,1,0,0V50a0,0,0,0,1,0,0H24A24,24,0,0,1,0,26V24A24,24,0,0,1,24,0Z"
            transform="translate(1820 955) rotate(180)"
          ></path>
          <path
            stroke="#818181"
            fill={mode === "dark" ? "#121212" : "#e2e2e2"}
            d="M24,0H60a0,0,0,0,1,0,0V50a0,0,0,0,1,0,0H24A24,24,0,0,1,0,26V24A24,24,0,0,1,24,0Z"
            transform="translate(1701 905)"
          ></path>
          <path
            fill={mode === "dark" ? "#5d5d5d" : "#121212"}
            d="M33.747,194.746a1.658,1.658,0,1,0,0,3.316h3.3a12.007,12.007,0,0,0,2.331,5.619l-2.338,2.338a1.658,1.658,0,0,0,2.344,2.344l2.338-2.338a12.007,12.007,0,0,0,5.619,2.331v3.3a1.658,1.658,0,1,0,3.316,0v-3.3a12.007,12.007,0,0,0,5.619-2.331l2.338,2.338a1.658,1.658,0,0,0,2.344-2.344l-2.338-2.338a12.008,12.008,0,0,0,2.331-5.619h3.3a1.658,1.658,0,0,0,0-3.316h-3.3a12.005,12.005,0,0,0-2.331-5.619l2.338-2.338a1.658,1.658,0,0,0-2.345-2.344l-2.338,2.338a12.007,12.007,0,0,0-5.619-2.331v-3.3a1.658,1.658,0,0,0-3.316,0v3.3a12.01,12.01,0,0,0-5.619,2.331l-2.338-2.338a1.658,1.658,0,0,0-2.344,2.344l2.338,2.338a12.008,12.008,0,0,0-2.331,5.619h-3.3ZM49,187.651a8.753,8.753,0,1,1-8.754,8.753A8.763,8.763,0,0,1,49,187.651Z"
            transform="translate(1681.999 733.596)"
          ></path>
          <path
            fill={mode === "dark" ? "#ffffff" : "#121212"}
            d="M358.249,191.252l-.033.01a14.492,14.492,0,1,0,18.654,18.654c0-.011.006-.022.01-.033A14.507,14.507,0,0,0,358.249,191.252Zm5.048,24.757a11.186,11.186,0,0,1-11.157-11.8c0-.041.007-.082.009-.123a11.221,11.221,0,0,1,4.229-8.021,14.506,14.506,0,0,0,15.684,15.685A11.224,11.224,0,0,1,363.3,216.009Zm10.709-7.985a11.171,11.171,0,0,1-13.9-13.9A11.184,11.184,0,0,1,374.006,208.024Z"
            transform="matrix(0.259, -0.966, 0.966, 0.259, 1498.128, 1227.92)"
          ></path>
        </g>
      </svg>
    </Toggle>
  );
};

export default ToggleButton;
