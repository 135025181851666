import React, { useContext, useEffect, useState } from "react";
import { MainLayout } from "../../layouts/main/main";
import BlogDetail from "../../components/blogDetail/blogDetail";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../../context/AppContext";

const BlogDetailPage = () => {
  const {updateLoader } = useContext(AppContext);
  const { id } = useParams();
  const [blogs, setBlogs] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const getPostData = async () => {
    try {
      updateLoader(true);
      const response = await axios.post(
        `${apiUrl}/clevermedium_backend/getpost.php`,
        {
          path: id,
        }
      );
      setBlogs(response.data.data);
      updateLoader(false);
    } catch (error) {
      console.error("Error during authentication:", error.message);
      updateLoader(false);
    }
  };
  useEffect(() => {
    getPostData();
  }, []);
  return <MainLayout>{blogs && <BlogDetail blog={blogs} />}</MainLayout>;
};

export default BlogDetailPage;
