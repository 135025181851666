import React from "react";
import { MainLayout } from "../../layouts/main/main";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import HelmetComponet from "../../components/helmet/helmet";

const PrivacyPolicy = () => {
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <MainLayout>
      <HelmetComponet
        title="Privacy Policy"
        description="Read the privacy policy of clevermedium.com to understand how we collect, use, and protect your information."
        path="https://clevermedium.com/privacy-policy"
        keywords="Privacy Policy, clevermedium, data protection, user privacy"
        image=""
      />
      <Stack my={3} className="pages">
        <Typography
          variant="h1"
          component="h1"
          sx={{ fontWeight: "bold" }}
          fontSize={matches ? "2em" : "1.5em"}
          mb={1}
        >
          Privacy Policy
        </Typography>
        {/* <p>Effective Date: [Insert Date] </p> */}
        <p>
          At <a href="https://clevermedium.com/">clevermedium.com</a>, your
          privacy is of utmost importance to us. This Privacy Policy explains
          how we collect, use, disclose, and protect your information when you
          visit our website. By using{" "}
          <a href="https://clevermedium.com/">clevermedium.com</a>, you consent
          to the practices described in this policy.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          1. Information We Collect
        </Typography>
        <ul>
          <b>a.Personal Information</b>
          <p>
            We may collect the following personal information when you interact
            with our website:
          </p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>
              Any other information you voluntarily provide via contact forms or
              subscription options
            </li>
          </ul>
          <b>b. Non-Personal Information</b>
          <p>
            We also collect non-personal data to improve user experience and
            optimize website performance, such as:
          </p>
          <ul>
            <li>Browser type and version</li>
            <li>Operating system</li>
            <li>IP address</li>
            <li>Time of visit and pages viewed</li>
          </ul>
          <b>c. Cookies and Tracking Technologies</b>
          <p>
            We use cookies and similar technologies to enhance your browsing
            experience. These may include:
          </p>
          <ul>
            <li>
              <b>Essential Cookies:</b> Necessary for website functionality.
            </li>
            <li>
              <b>Analytics Cookies:</b> To understand user behavior and improve
              content.
            </li>
            <li>
              <b>Advertising Cookies:</b> To deliver personalized ads.
            </li>
          </ul>
        </ul>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          2. How We Use Your Information
        </Typography>
        <p>The information we collect is used for the following purposes:</p>
        <ul>
          <li>To provide and maintain our services.</li>
          <li>
            To communicate with you via newsletters, updates, or responses to
            your inquiries.
          </li>
          <li>To analyze website usage and improve content.</li>
          <li>
            To comply with legal obligations and prevent fraudulent activity.
          </li>
        </ul>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          3. Sharing of Information
        </Typography>
        <p>
          We do not sell or rent your personal information. However, we may
          share data under the following circumstances:
          <ul>
            <li>
              <b>Third-Party Service Providers:</b> For email campaigns, website
              analytics, and similar services (e.g., Google Analytics).
            </li>
            <li>
              <b> Legal Requirements:</b> When required to comply with legal
              obligations.
            </li>
            <li>
              <b>Business Transfers: In case of mergers,</b> acquisitions, or
              sales of business assets.
            </li>
          </ul>
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          4. Data Security
        </Typography>
        <p>
          We take data security seriously and implement the following measures:
        </p>
        <ul>
          <li>Secure servers with encryption protocols.</li>
          <li>
            Limited access to personal information by authorized personnel only.
          </li>
          <li>Regular audits and updates to our security systems.</li>
        </ul>
        <p>
          However, no method of transmission over the internet is 100% secure,
          and we cannot guarantee absolute security.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          5. Your Rights
        </Typography>
        <p>As a user, you have the following rights regarding your data:</p>
        <ul>
          <li>
            <b>Access: </b>Request a copy of the information we hold about you.
          </li>
          <li>
            <b>Correction:</b> Update inaccurate or incomplete data.
          </li>
          <li>
            <b>Deletion:</b> Request deletion of your personal data, subject to
            legal requirements.
          </li>
          <li>
            <b>Opt-Out:</b> Unsubscribe from marketing emails or adjust cookie
            settings.
          </li>
        </ul>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          6. Childrens Privacy
        </Typography>
        <p>
          <a href="https://clevermedium.com/">clevermedium.com</a> does not
          knowingly collect personal information from children under the age of
          13. If you believe we have collected such data, please contact us
          immediately.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          7. Third-Party Services
        </Typography>
        <p>We utilize third-party tools and services, such as:</p>
        <ul>
          <li>
            Google Analytics: For tracking website performance and user
            behavior.
          </li>
          <li>
            Email Marketing Providers: For managing newsletters and
            communications.
          </li>
        </ul>
        <p>
          These services may collect information as per their privacy policies.
          We recommend reviewing those policies to understand their practices.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          8. Cookies and Tracking Technologies
        </Typography>
        <ul>
          <li>Recognize your preferences.</li>
          <li>Analyze website performance.</li>
          <li>Provide targeted advertisements.</li>
        </ul>
        <b>Managing Cookies</b>
        <p>
          You can adjust your browser settings to refuse or delete cookies. Note
          that some website features may not function properly if cookies are
          disabled.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          9. Updates to This Policy
        </Typography>
        <p>
          We may update this Privacy Policy periodically to reflect changes in
          our practices or legal requirements. Updates will be posted on this
          page, and the  Date will be revised. We encourage you to
          review this policy regularly.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          10. Contact Us
        </Typography>
        <p>
          If you have questions, concerns, or requests related to this Privacy
          Policy, please contact us at:
        </p>
        <b>Email: </b>{" "}
        <a href="mailto:contact@clevermedium.com">contact@clevermedium.com</a>
        <p>
          Thank you for trusting{" "}
          <a href="https://clevermedium.com/">clevermedium.com</a>. Your privacy
          is our priority.
        </p>
      </Stack>
    </MainLayout>
  );
};

export default PrivacyPolicy;
