import { jwtDecode } from "jwt-decode";

const TOKEN_KEY = "token";

export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem("user");
};

export const isAuthenticated = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return token && !isTokenExpired(token);
};

export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Token expired
      localStorage.removeItem(TOKEN_KEY);
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};
