import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import TimeAgo from "./../../hooks/TimeAgo";
import useShare from "../../hooks/useShare";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { isAuthenticated } from "../../routers/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const BloggerProfile = ({ name, date, blog }) => {
  // const [likes, setLikes] = useState(0);
  const [totalLikes, setTotalLikes] = useState(0);
  const { isSupported, shareContent } = useShare(
    blog.path,
    blog.title,
    blog.content
  );
  const authenticated = isAuthenticated();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleLike = async () => {
    if (!authenticated) {
      navigate("/login");
      return;
    }
    const { userid } = JSON.parse(localStorage.getItem("user")) || {};

    try {
      const response = await axios.post(
        `${apiUrl}/clevermedium_backend/likes.php`,
        {
          post_id: blog.post_id,
          user_id: userid,
        }
      );
      if (response.data.liked === true) {
        setTotalLikes(totalLikes + 1);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching total likes:", error);
    }
  };

  useEffect(() => {
    const fetchTotalLikes = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}/clevermedium_backend/get-total-likes.php`,
          {
            post_id: blog.post_id,
          }
        );
        setTotalLikes(response.data.total_likes);
      } catch (error) {
        console.error("Error fetching total likes:", error);
      }
    };

    if (blog.post_id) {
      fetchTotalLikes();
    }
  }, [blog.post_id]);

  // Add this line at the top of the component
  return (
    <Stack
      direction="row"
      pb={2}
      justifyContent={"space-between"}
      style={{ borderBottom: "1px solid #eee" }}
    >
      <Stack spacing={2} direction="row">
        <Avatar>H</Avatar>
        <Stack direction="column">
          <Typography textTransform={"capitalize"} variant="body2">
            {name}
          </Typography>
          <Stack fontSize={14} direction="row" spacing={2}>
            <TimeAgo date={date} />
          </Stack>
        </Stack>
      </Stack>
      <Box display={"flex"}>
        <Stack
          direction="row"
          alignItems="center"
          onClick={handleLike}
          title="Like"
        >
          <ThumbUpIcon style={{ fontSize: 18 }} />
          <Typography marginLeft={0.5} variant="body2">
            {totalLikes}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" mx={2} title="Views">
          <VisibilityIcon style={{ fontSize: 18 }} />
          <Typography marginLeft={0.5} variant="body2">
            {blog.page_count}
          </Typography>
        </Stack>
        <IconButton
          onClick={shareContent}
          disabled={!isSupported}
          aria-label="share"
          title="Share"
        >
          <ShareIcon style={{ fontSize: 18 }} />
        </IconButton>
      </Box>
    </Stack>
  );
};

export default BloggerProfile;
