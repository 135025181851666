import { styled } from "@mui/material/styles";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContainer = styled("div")(({ theme }) => ({
  flex: 1,
  padding: "0 64px 32px",
  maxWidth: "1024px",
  width: "calc(100% - 128px)",
  margin: "auto",
  backgroundColor: theme.palette.text.paper,
  [theme.breakpoints.down("sm")]: {
    padding: "0",
    width: "calc(100% - 32px)",
  },
}));

export { Container, MainContainer };
