import React from "react";
import { Logo, HeaderContainer, ToolbarContainer } from "./headerStyle";
import { Link } from "react-router-dom";
import CleverLogo from "../logo/logo";
import { useTheme } from "@emotion/react";
import { isAuthenticated } from "./../../routers/auth";
import AccountMenu from "../../admin/accountMenu/accountMenu";
import LoginIcon from "@mui/icons-material/Login";

const Header = () => {
  const theme = useTheme();
  const authenticated = isAuthenticated();
  return (
    <HeaderContainer
      position="static"
      color="transparent"
      style={{ borderColor: theme.palette.divider }}
    >
      <ToolbarContainer>
        <Logo py={1}>
          <Link to="/">
            <CleverLogo mode={theme.palette.mode} />
          </Link>
        </Logo>
        {!authenticated && (
          <Link to="/login" style={{display:'flex', textDecoration:'none'}}>
            <LoginIcon sx={{ marginRight: '0.5rem' }} />
            Login
          </Link>
        )}
        {authenticated && <AccountMenu />}
      </ToolbarContainer>
    </HeaderContainer>
  );
};

export default Header;
