import { Link } from "react-router-dom";
import styled from "styled-components";
// import { styled } from "@mui/system";

export const FooterContainer = styled.footer`
  border-top: 1px solid;
  color: #000;
  padding: 8px 16px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledLink = styled(Link)`
  /* color: #000; */
  text-decoration: none;
  margin: 0 1rem;
`;
