import { Typography } from "@mui/material";
import { FooterContainer, StyledLink } from "./footerStyle";
import Box from "@mui/material/Box";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  const theme = useTheme();
  const d = new Date();
  const year = d.getFullYear();
  return (
    <FooterContainer style={{ borderColor: theme.palette.divider }}>
      <Box>
        <StyledLink px={2} to="/about-us" underline="none">
          <Typography component="body1" color={theme.palette.text.secondary}>
            About us
          </Typography>
        </StyledLink>
        <StyledLink px={2} to="/terms-and-conditions" underline="hover">
          <Typography component="body1" color={theme.palette.text.secondary}>
            Terms
          </Typography>
        </StyledLink>
        <StyledLink px={2} to="/privacy-policy" underline="hover">
          <Typography component="body1" color={theme.palette.text.secondary}>
            Privacy Policy
          </Typography>
        </StyledLink>
      </Box>
      <Link
          to="https://www.linkedin.com/company/clevermedium/"
          target="_blank"
        >
          <LinkedInIcon style={{ fill: theme.palette.text.primary }} />
        </Link>
      <Box>
        <Typography component="body1" color={theme.palette.text.secondary}>
          ©{year} Clevermedium. All rights reserved.
        </Typography>
        
      </Box>
    </FooterContainer>
  );
};

export default Footer;
